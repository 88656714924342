.choroplethLegend {
    background: white;
    padding: 1em;
    line-height: 0;
    font-family:sans-serif;
    border: 1px solid grey;

    position: absolute;
    top: 150px;
    left: 10px;
    background-color: white;
}

.choroplethLegendBox {
    font-size: 25px;
    margin:0;
    display: inline-block;
    width: 1em;
    height: 1em;
}
.choroplethLegendLabel {
    vertical-align:super;
    font-size:10pt;
    padding-left:1em;
}