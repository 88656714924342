.mainGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
        'Tutorial Select';
    grid-template-columns: 610px auto; 
    grid-gap: 5px;
    border-color: lightgray;
    width: 940px;
    min-height: 700px;
}

.SelectOnlyGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
        'Select';
    width: 100%;
}

.mainGridItemTutorial{
    grid-area: Tutorial;
    border-right: 2px var(--duke-blue) solid;
}

.mainGridItemSelect{
    grid-area: Select;
    margin-right: 10px;
}

.header{
     color: white;
    background-color: var(--duke-blue);
    padding: 1rem;
}

.headerCloseButton{
    width: 2rem;
    height: 2rem;
    color: white;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    float: right;
    font-size: large;
    cursor: pointer;
}

.option{
    margin-top: 10px;
}

.submitButton{
    float: right;
    margin-top: 10px;
}


.showTutorialButton{
    cursor: pointer;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
}

.tutorialHeader{
    color: var(--duke-blue);
    font-size: large;
    font-weight: bold;

}

.tutorialImage{
    border: 0px;
    display: flex;
    justify-content: center;
}

.tutorialFooter{
    margin-top: 5px;
    white-space: pre-wrap;
}

.tutorialPaginator{
    position: sticky;
    bottom: 5px;
    padding: 0px;
}

.tutorialNote{
    border-style: solid;
    border-width: 1px;
    border-radius: 25px;
    text-align: center;
    padding: 2px;
    margin-bottom: 10px;
    background-color: var(--duke-blue);
    color: white;
}