.loginMain{
    background-color: lightgrey;
    height: 100vh;
    position: relative;
}

.loginBlock{
    background-color: var(--duke-blue);
    color: white;
    position: absolute;
    width: 400px;
    min-height: 280px;
    top: calc(50% - 130px);
    left: calc(50% - 200px);
    border: solid;
    border-color: black;
    padding: 30px;
}

.label{
    margin-top: 20px;
}

.input{
    width: 100%;
    margin-top: 5px;
}

.button{
    margin-top: 10px;
    width: 100%;
    border-radius: 25px;
}

.error{
    color: red;
    margin-top: 10px;
}