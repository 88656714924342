.mainContainer{
    display: block;
    width: 100%;
    background: var(--duke-blue);
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.header{
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
}

.dukeLogo{
    flex: 1 1 0%;
}

.dukeLogoImage{
    padding: 10px;
}

.navigation{
    display: flex;
    flex-direction: column;
    padding-top: 5px;
}


.optionsGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
      'explore monitor about faq tutorial';      
    width:300px;
    height:100%; 
    background: transparent;
    margin-right: 50px;
    margin-top: 30px;
}

.optionItemExplore{
    grid-area: explore;
}

.optionItemMonitor{
    grid-area: monitor;
    
}

.optionItemAbout{
    grid-area: about;
}

.optionItemFaq{
    grid-area: faq;
}

.optionItemTutorial{
    grid-area: tutorial;
    color: white;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
}


.optionItem{
    color: white;    
    text-align: center;
    padding-top: 5px;
    cursor: pointer;
    font-size: 1rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
}

.dukeLogo{
    height: 80px;
}

.thsLogo{
    height: 80px;
}