.modal{
    width: 380px;
}

.container{
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    justify-content: center;
}

.legendColorItem{
    width: 32px;
    height: 16px;
    margin: 2px;
}