.mainMapContainer {
    position: relative;
    height: calc(100vh - 80px);
    overflow:auto;
}

.gridContainer {
    position: absolute;
    display: grid;
    grid-template-areas:
        'Selection ChangeSelection'  
        'Map Detail'; 
    grid-template-columns:70% auto; 
    grid-template-rows: 60px auto;
    width:100%;
    grid-gap: 5px;
    border-color: lightgray;
    top: 0px;
    bottom: 0px;
}

.gridContainerNoSelection {
    position: relative;
    display: grid;
    grid-template-areas:
        'Map'; 
    grid-template-columns:100%; 
    grid-template-rows: 100%;
    width:100%;
    height:100%; 
    grid-gap: 10px;
    border-color: lightgray;
}

.gridItemSelection{
    grid-area: Selection;
    position: relative;
}

.gridItemChangeSelection{
    grid-area: ChangeSelection;
    position: relative;
    padding: 10px 10px 0px 0px;
}

.gridItemMap{
    grid-area: Map;
    position: relative;
    height: 100%;
}

.gridItemMapNoSelect{
    grid-area: Map;
    position: relative;
    height: 100%;
}

.gridItemDetail{
    grid-area: Detail;
    padding-right: 10px;
}


.mainBody{
     height: 100%;
}

.selectionDisplay{
    position: absolute;
    top: 10px;
    left: 10px;
    border: 1px solid grey;
    padding: 1em;
    
    display: grid;
    grid-template-areas:
        'Selection Legend'  
        'Detail Legend'; 


    background-color: #012169;
    color: white;
    opacity: 0.8;
}

.selectionItemSelection{
    grid-area: Selection;
}

.selectionItemDetail{
    grid-area: Detail;
}

.selectionItemLegend{
    grid-area: Legend;
    display: flex;
    margin-left: 60px;
    justify-content: center;
    align-items: center;
}

.choroplethLegendValueFirst{
    margin-right: 10px;
}

.choroplethLegendValueLast{
    margin-left: 10px;
}

.optionHeader{
 color: black;
}

.optionColumnDisplayPanel{
    cursor: pointer;
    border: 1px solid grey;
    background-color: aliceblue;
    padding: 10px;
    font-weight: 600;
}

.optionColumnSelectedPanel{
    background-color: lightsteelblue;
}

.selectionLabel{
    font-weight: bold;
    font-size: xx-large;
    position: absolute;
    bottom: 0px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
}

.searchButton{
    background: linear-gradient(to left, var(--duke-blue) 50%, var(--indigo-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--duke-blue);
    padding: 0px;    width: 100%;
    margin-top: 15px;
}


.searchButton:hover{
    background-position: left bottom;
}

.searchButton:focus{
    box-shadow: 0 0 0 1px var(--indigo-700);
}

.searchButton i{
    background-color: var(--indigo-700);
    width: 40px;
    line-height: 2.25rem;
    margin-right: 10px;
    
}

.relocateIconParent{
    position: relative;
    width: 25px;
}
.relocateIcon{
    position: absolute;
    bottom: 5px;
    margin-right: 10px;
    height: 30px;
    cursor: pointer;
}

.MonitorButton{
    margin-top: 10px;
    width: 100%;
    text-align: center;
    background-color: #012169;
}

.valueNotAvailable{
    color: gray;
    font-style: italic;
}