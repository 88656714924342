.mainContainer{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mainContainerGrey{
    background-color: #F2F2F2;
    padding-bottom: 40px;
}


.mainContainerRecommendItem{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
}

.mainBody{
     width: 70%;
}

.divider{
    padding-top: 20px;
    padding-bottom: 20px;
}

.reportTitle{
    color: #012169;
    font-size: x-large;
    font-weight: bolder;
    margin-top: 40px;
}

.title{
    color: #012169;
    font-size: large;
    font-weight: bolder;
    margin-top: 40px; 
}

.subTitle{
    color: #012169;
    font-size: large;
    font-weight: bolder;
    margin-top: 10px;
}

.subTitle2{
    color: #012169;
    font-size: medium;
    font-weight: bolder;
    margin-top: 10px;
}

.noMargin{
    margin-top: 0px;
}

.body{
    margin-top: 10px;
    font-size: medium;
}

.bodyHighlighted{
    font-size: medium;
    color: #012169;
    font-weight: bold;
}

.exampleHeader{
    font-style: italic;
}

.source{
    font-style: italic;
    font-size: small;
}

.recommendContainer{
    display: flex;
    flex-direction: row;
}

.recommendItem{
    width: 25%;
    padding: 10px;
}

.needHigh{
    background-color: #FEEAEB;
}

.needHigh .subTitle2 {
    color: red;
}

.needModerate{
    background-color: #FCF1ED;
}

.needModerate .subTitle2 {
    color: #ED7C31;
}

.needLow{
    background-color: #F9F5EA;
}

.needLow .subTitle2 {
    color: #D3A005;
}

.needLowest{
    background-color: #B3F7AF;
}

.needLowest .subTitle2 {
    color: green;
}

