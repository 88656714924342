.mainGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
        'Selection'  
        'Body'; 
    grid-template-rows: 60px auto;
    width:100%;
    min-width: 1110px;
    grid-gap: 5px;
    border-color: lightgray;
    font-size: small;
}

.mainGridItemSelection{
    grid-area: Selection;
    position: relative;
}

.mainGridItemBody{
    grid-area: Body;
    position: relative;
    padding: 10px;
}

.selectionLabel{
    font-weight: bold;
    font-size: xx-large;
    position: absolute;
    bottom: 0px;
    margin-left: 10px;
}

.scoreGridContainerHeader {
    position: relative;
    display: grid;
    grid-template-areas:
        'group index need county state national class';
    grid-template-columns: 200px 180px 300px auto auto auto 20%;
    margin-right: 10px;
    width:100%;
    height:auto; 
    border-color: lightgray;
}

.scoreGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
        'group index need need need need class';
    grid-template-columns: 200px 180px 300px auto auto auto 20%;
    margin-right: 10px;
    width:100%;
    height:auto; 
    border-color: lightgray;
}

.valuesGridContainer{
    position: relative;
    display: grid;
    grid-template-areas:
        'need county state national';
    grid-template-columns: 298px auto auto auto;
    width:100%;
    height:auto; 
    border-color: lightgray;
}

.scoreItemGroup{
    grid-area: group; 
    border: solid 2px;
    border-right: none;
    border-top: none;  
    border-color: black;
    color: white;
    font-size: large;
    font-weight: bold;
}

.scoreItemIndex{
    grid-area: index; 
    border: solid 2px;
    padding: 10px;
    border-top: none;    
    border-right: none;
}

.scoreItemNeed{
    grid-area: need; 
    padding: 10px;
    border: solid 2px;
    border-top: none;    
    border-right: none;
    display: flex;
    flex-direction: column;
    }

    .scoreItemNeedNoPadding{
        padding: 0px;
        }

    .valueItemNeed{
        grid-area: need; 
        padding: 10px;
        display: flex;
        flex-direction: column;
        }    

.scoreItemCounty{
    grid-area: county; 
    padding: 10px;
    border: solid 2px;
    border-top: none;    
    border-right: none;  
    border-left: none;
    text-align: center;
}

.valueItemCounty{
    grid-area: county; 
    text-align: center;
}

.scoreItemState{
    grid-area: state; 
    border: solid 2px;
    padding: 10px;
    border-top: none;    
    border-right: none;
    border-left: none;
    text-align: center
}

.valueItemState{
    grid-area: state; 
    text-align: center;
}

.scoreItemNational{
    grid-area: national; 
    border: solid 2px;
    padding: 10px;
    border-top: none;    
    border-right: none;
    border-left: none;
    text-align: center
}

.valueItemNational{
    grid-area: national; 
    text-align: center;
}

.gridItemMinWidth100{
    min-width: 100px
}

.scoreItemClass{
    grid-area: class; 
    border: solid 2px;
    padding: 10px;
    border-top: none;
    }

.scoreHeaderFirst{
    border-left: none;
}

.scoreHeader{
    border-top: solid 2px;
    text-align: center;
    background-color: lightgray;
}

.scoreHeaderTextAlignStart{
    border-top: solid 2px;
    padding-left: 5px;
    text-align: start;
    background-color: lightgray;
}

.scoreNeedItem{
    padding: 5px;
    border-bottom:solid 1px lightGrey;
}

.scoreNeedItemLast{
    padding: 5px;
}

.needClassItem{
    padding: 10px;
    border: solid 1px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
}

.needClassItemLast{
    padding: 10px;
    border: solid 1px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
}



.scoreIndicator{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.centerAndJustifyContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.groupGridContainer {
    position: relative;
    display: grid;
    grid-template-areas:
        'groupIcon'  
        'groupName'; 
    grid-template-rows: auto 20px;
    height: fit-content;
    margin-bottom: 10px;
    margin-top: 10px;
}

.groupItemIcon{
    grid-area: groupIcon; 
}

.icon{
    height: 80px;
    margin-bottom: 20px;
}

.groupItemName{
    grid-area: groupName; 
    text-align: center;
}

.reportButtonContainer{
    float: right;
    margin-right: 15px;
    margin-top: 20px;
    width: 230px;
}

.reportButton{
    width: 100%;
    padding: 10px;
    background-color: var(--duke-blue);
    color: white;
    font-size: large;
    text-align: center;
}

.valueNotAvailable{
    color: gray;
    font-style: italic;
}