@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700);
body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selector_mainGridContainer__2pxMT {
    position: relative;
    display: grid;
    grid-template-areas:
        'Tutorial Select';
    grid-template-columns: 610px auto; 
    grid-gap: 5px;
    border-color: lightgray;
    width: 940px;
    min-height: 700px;
}

.selector_SelectOnlyGridContainer__2WH2v {
    position: relative;
    display: grid;
    grid-template-areas:
        'Select';
    width: 100%;
}

.selector_mainGridItemTutorial__3-P8p{
    grid-area: Tutorial;
    border-right: 2px var(--duke-blue) solid;
}

.selector_mainGridItemSelect__2QZ-l{
    grid-area: Select;
    margin-right: 10px;
}

.selector_header__DBZZ4{
     color: white;
    background-color: var(--duke-blue);
    padding: 1rem;
}

.selector_headerCloseButton__1E_4G{
    width: 2rem;
    height: 2rem;
    color: white;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    float: right;
    font-size: large;
    cursor: pointer;
}

.selector_option__1s9Pm{
    margin-top: 10px;
}

.selector_submitButton__2GSRM{
    float: right;
    margin-top: 10px;
}


.selector_showTutorialButton__aDL_K{
    cursor: pointer;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
}

.selector_tutorialHeader__28s0u{
    color: var(--duke-blue);
    font-size: large;
    font-weight: bold;

}

.selector_tutorialImage__2V_QK{
    border: 0px;
    display: flex;
    justify-content: center;
}

.selector_tutorialFooter__KQwXU{
    margin-top: 5px;
    white-space: pre-wrap;
}

.selector_tutorialPaginator__3RunU{
    position: -webkit-sticky;
    position: sticky;
    bottom: 5px;
    padding: 0px;
}

.selector_tutorialNote__1kkkE{
    border-style: solid;
    border-width: 1px;
    border-radius: 25px;
    text-align: center;
    padding: 2px;
    margin-bottom: 10px;
    background-color: var(--duke-blue);
    color: white;
}
.choropleth_choroplethLegend__1VHkN {
    background: white;
    padding: 1em;
    line-height: 0;
    font-family:sans-serif;
    border: 1px solid grey;

    position: absolute;
    top: 150px;
    left: 10px;
    background-color: white;
}

.choropleth_choroplethLegendBox__2hNlM {
    font-size: 25px;
    margin:0;
    display: inline-block;
    width: 1em;
    height: 1em;
}
.choropleth_choroplethLegendLabel__PYvJ8 {
    vertical-align:super;
    font-size:10pt;
    padding-left:1em;
}
.choropleth\.popup_mainGridContainer__26pZU {
    position: relative;
    display: grid;
    grid-template-areas:
        'name'  
        'valueName'
        'value'; 
    grid-gap: 5px;
    border-color: lightgray;
}

.choropleth\.popup_mainGridItemName__1ITqD{
    grid-area: name;
    font-size: medium;
    font-weight: bold;
}

.choropleth\.popup_mainGridItemValueName__2uAgm{
    grid-area: valueName;
    font-size: small;
}

.choropleth\.popup_mainGridItemValue__1fiV1{
    grid-area: value;
    font-size: small;
}


.infoModal_dialog__9AFBR{
    width: 550px;
    min-height: 480px;
}

.infoModal_header__3CK77{
    color: white;
    background-color: #012169;
    padding: 1rem;
    width: 100%;
    display: inline-flex;
}

.infoModal_headerGrid__Upf5Z{
    display: grid;
    grid-template-areas:
      'title button';  
    columns: auto 50px;
    width: 100%;
}

.infoModal_headerTitle__3q1iO{
    grid-area:title
}

.infoModal_headerButton__2DI7S{
    position: relative;
    grid-area: button;
}

.infoModal_headerCloseButton__3vAhB{
    width: 30px;
    height: 30px;
    color: white;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    float: right;
    font-size: large;
    cursor: pointer;
}

.infoModal_headerCloseButtonHover__1ohcW{
    color: black;
}

.infoModal_title__2gdDR{
    font-weight: 500;
    width: 150px;
    height: 30px;
}

.infoModal_data__2MDOG{
    padding-left: 3px;
}


.infoModal_source__2dwTv{
    padding-left: 3px;
    font-size: smaller;
}



.infoModal_progressSpinner__3Mv8A{

    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
}
.mainMap_mainMapContainer__3dj4r {
    position: relative;
    height: calc(100vh - 80px);
    overflow:auto;
}

.mainMap_gridContainer__1YIFH {
    position: absolute;
    display: grid;
    grid-template-areas:
        'Selection ChangeSelection'  
        'Map Detail'; 
    grid-template-columns:70% auto; 
    grid-template-rows: 60px auto;
    width:100%;
    grid-gap: 5px;
    border-color: lightgray;
    top: 0px;
    bottom: 0px;
}

.mainMap_gridContainerNoSelection__HuVt8 {
    position: relative;
    display: grid;
    grid-template-areas:
        'Map'; 
    grid-template-columns:100%; 
    grid-template-rows: 100%;
    width:100%;
    height:100%; 
    grid-gap: 10px;
    border-color: lightgray;
}

.mainMap_gridItemSelection__FTF7A{
    grid-area: Selection;
    position: relative;
}

.mainMap_gridItemChangeSelection__1Q88H{
    grid-area: ChangeSelection;
    position: relative;
    padding: 10px 10px 0px 0px;
}

.mainMap_gridItemMap__29I7w{
    grid-area: Map;
    position: relative;
    height: 100%;
}

.mainMap_gridItemMapNoSelect__1qpra{
    grid-area: Map;
    position: relative;
    height: 100%;
}

.mainMap_gridItemDetail__1OtT0{
    grid-area: Detail;
    padding-right: 10px;
}


.mainMap_mainBody__wjwR4{
     height: 100%;
}

.mainMap_selectionDisplay__3nmqq{
    position: absolute;
    top: 10px;
    left: 10px;
    border: 1px solid grey;
    padding: 1em;
    
    display: grid;
    grid-template-areas:
        'Selection Legend'  
        'Detail Legend'; 


    background-color: #012169;
    color: white;
    opacity: 0.8;
}

.mainMap_selectionItemSelection__URHBy{
    grid-area: Selection;
}

.mainMap_selectionItemDetail__xMY4-{
    grid-area: Detail;
}

.mainMap_selectionItemLegend__1Ah6n{
    grid-area: Legend;
    display: flex;
    margin-left: 60px;
    justify-content: center;
    align-items: center;
}

.mainMap_choroplethLegendValueFirst__3mfmA{
    margin-right: 10px;
}

.mainMap_choroplethLegendValueLast__JsHqO{
    margin-left: 10px;
}

.mainMap_optionHeader__1G3QF{
 color: black;
}

.mainMap_optionColumnDisplayPanel__VfcZP{
    cursor: pointer;
    border: 1px solid grey;
    background-color: aliceblue;
    padding: 10px;
    font-weight: 600;
}

.mainMap_optionColumnSelectedPanel__eX6GU{
    background-color: lightsteelblue;
}

.mainMap_selectionLabel__27iCu{
    font-weight: bold;
    font-size: xx-large;
    position: absolute;
    bottom: 0px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
}

.mainMap_searchButton__2IcIN{
    background: linear-gradient(to left, var(--duke-blue) 50%, var(--indigo-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--duke-blue);
    padding: 0px;    width: 100%;
    margin-top: 15px;
}


.mainMap_searchButton__2IcIN:hover{
    background-position: left bottom;
}

.mainMap_searchButton__2IcIN:focus{
    box-shadow: 0 0 0 1px var(--indigo-700);
}

.mainMap_searchButton__2IcIN i{
    background-color: var(--indigo-700);
    width: 40px;
    line-height: 2.25rem;
    margin-right: 10px;
    
}

.mainMap_relocateIconParent__1DQuM{
    position: relative;
    width: 25px;
}
.mainMap_relocateIcon__3gMQG{
    position: absolute;
    bottom: 5px;
    margin-right: 10px;
    height: 30px;
    cursor: pointer;
}

.mainMap_MonitorButton__309Rl{
    margin-top: 10px;
    width: 100%;
    text-align: center;
    background-color: #012169;
}

.mainMap_valueNotAvailable__Yv0jl{
    color: gray;
    font-style: italic;
}
.scoreCard_mainGridContainer__NgCD_ {
    position: relative;
    display: grid;
    grid-template-areas:
        'Selection'  
        'Body'; 
    grid-template-rows: 60px auto;
    width:100%;
    min-width: 1110px;
    grid-gap: 5px;
    border-color: lightgray;
    font-size: small;
}

.scoreCard_mainGridItemSelection__3FhHM{
    grid-area: Selection;
    position: relative;
}

.scoreCard_mainGridItemBody__2-i6z{
    grid-area: Body;
    position: relative;
    padding: 10px;
}

.scoreCard_selectionLabel__BBkGA{
    font-weight: bold;
    font-size: xx-large;
    position: absolute;
    bottom: 0px;
    margin-left: 10px;
}

.scoreCard_scoreGridContainerHeader__2_5n8 {
    position: relative;
    display: grid;
    grid-template-areas:
        'group index need county state national class';
    grid-template-columns: 200px 180px 300px auto auto auto 20%;
    margin-right: 10px;
    width:100%;
    height:auto; 
    border-color: lightgray;
}

.scoreCard_scoreGridContainer__2COBI {
    position: relative;
    display: grid;
    grid-template-areas:
        'group index need need need need class';
    grid-template-columns: 200px 180px 300px auto auto auto 20%;
    margin-right: 10px;
    width:100%;
    height:auto; 
    border-color: lightgray;
}

.scoreCard_valuesGridContainer__1-Pmp{
    position: relative;
    display: grid;
    grid-template-areas:
        'need county state national';
    grid-template-columns: 298px auto auto auto;
    width:100%;
    height:auto; 
    border-color: lightgray;
}

.scoreCard_scoreItemGroup__3QaOp{
    grid-area: group; 
    border: solid 2px;
    border-right: none;
    border-top: none;  
    border-color: black;
    color: white;
    font-size: large;
    font-weight: bold;
}

.scoreCard_scoreItemIndex__251zB{
    grid-area: index; 
    border: solid 2px;
    padding: 10px;
    border-top: none;    
    border-right: none;
}

.scoreCard_scoreItemNeed__2B4oQ{
    grid-area: need; 
    padding: 10px;
    border: solid 2px;
    border-top: none;    
    border-right: none;
    display: flex;
    flex-direction: column;
    }

    .scoreCard_scoreItemNeedNoPadding__2ZqUV{
        padding: 0px;
        }

    .scoreCard_valueItemNeed__nJepH{
        grid-area: need; 
        padding: 10px;
        display: flex;
        flex-direction: column;
        }    

.scoreCard_scoreItemCounty__rtDOm{
    grid-area: county; 
    padding: 10px;
    border: solid 2px;
    border-top: none;    
    border-right: none;  
    border-left: none;
    text-align: center;
}

.scoreCard_valueItemCounty__3r31T{
    grid-area: county; 
    text-align: center;
}

.scoreCard_scoreItemState__35a4b{
    grid-area: state; 
    border: solid 2px;
    padding: 10px;
    border-top: none;    
    border-right: none;
    border-left: none;
    text-align: center
}

.scoreCard_valueItemState__1vE_D{
    grid-area: state; 
    text-align: center;
}

.scoreCard_scoreItemNational__3jFPM{
    grid-area: national; 
    border: solid 2px;
    padding: 10px;
    border-top: none;    
    border-right: none;
    border-left: none;
    text-align: center
}

.scoreCard_valueItemNational__3Qm8E{
    grid-area: national; 
    text-align: center;
}

.scoreCard_gridItemMinWidth100__JeWMD{
    min-width: 100px
}

.scoreCard_scoreItemClass__263M8{
    grid-area: class; 
    border: solid 2px;
    padding: 10px;
    border-top: none;
    }

.scoreCard_scoreHeaderFirst__HW8jd{
    border-left: none;
}

.scoreCard_scoreHeader__gQPkI{
    border-top: solid 2px;
    text-align: center;
    background-color: lightgray;
}

.scoreCard_scoreHeaderTextAlignStart__2sIXu{
    border-top: solid 2px;
    padding-left: 5px;
    text-align: start;
    background-color: lightgray;
}

.scoreCard_scoreNeedItem__1x07U{
    padding: 5px;
    border-bottom:solid 1px lightGrey;
}

.scoreCard_scoreNeedItemLast__3B2qs{
    padding: 5px;
}

.scoreCard_needClassItem__24Q6N{
    padding: 10px;
    border: solid 1px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
}

.scoreCard_needClassItemLast__2cjXY{
    padding: 10px;
    border: solid 1px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
}



.scoreCard_scoreIndicator__i3hlD{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.scoreCard_centerAndJustifyContainer__CaDCV{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.scoreCard_groupGridContainer__1g_rm {
    position: relative;
    display: grid;
    grid-template-areas:
        'groupIcon'  
        'groupName'; 
    grid-template-rows: auto 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 10px;
    margin-top: 10px;
}

.scoreCard_groupItemIcon__xWo1W{
    grid-area: groupIcon; 
}

.scoreCard_icon__2vzAm{
    height: 80px;
    margin-bottom: 20px;
}

.scoreCard_groupItemName__KfGjZ{
    grid-area: groupName; 
    text-align: center;
}

.scoreCard_reportButtonContainer__1wjGk{
    float: right;
    margin-right: 15px;
    margin-top: 20px;
    width: 230px;
}

.scoreCard_reportButton__1L9CS{
    width: 100%;
    padding: 10px;
    background-color: var(--duke-blue);
    color: white;
    font-size: large;
    text-align: center;
}

.scoreCard_valueNotAvailable__3cALf{
    color: gray;
    font-style: italic;
}
.scoreCardLegend_modal__18DSa{
    width: 380px;
}

.scoreCardLegend_container__37H70{
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    justify-content: center;
}

.scoreCardLegend_legendColorItem__3udTn{
    width: 32px;
    height: 16px;
    margin: 2px;
}
.about_mainBody__2Ptt8{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.about_title__i3kXO{
    color: #012169;
    font-size: larger;
    font-weight: bold;
    margin-top: 10px;
}

.about_body__3BCBU{
    margin-top: 10px;
}

.about_ehLogo__1h0Ns{
    width: 400px;
}


.about_methodList__Z0jNs{
    list-style: none;
}

.about_logos__1nIA-{
    position: relative;
    display: grid;
    grid-template-areas:
        'Duke Elevance THS';
    width:100%;
    height:100px; 
    margin-top: 50px;
}

.about_dukeLogo__2pI2G{
    grid-area: Duke;
    height: 100px;
    background-color: var(--duke-blue);
    padding: 10px;
}

.about_elevanceLogo__2D1ES{
    grid-area: Elevance;
    height: 100px;
}

.about_thsLogo__3dtZ6{
    grid-area: THS;
    height: 100px;
    
}

@media (max-width: 1100px) {
    .about_dukeLogo__2pI2G{
        height: 80px;
    }

    .about_elevanceLogo__2D1ES{
        height: 80px;
    }
    
    .about_thsLogo__3dtZ6{
        height: 80px;
        
    }
}

@media (max-width: 900px) {
    .about_dukeLogo__2pI2G{
        height: 60px;
    }

    .about_elevanceLogo__2D1ES{
        height: 60px;
    }
    
    .about_thsLogo__3dtZ6{
        height: 60px;
        
    }
}

@media (max-width: 700px) {
    .about_logos__1nIA-{
        grid-template-areas:
        'Duke'
        'Elevance'
        'THS';
        height: 400px;
    }

    .about_dukeLogo__2pI2G{
        height: 60px;
    }

    .about_elevanceLogo__2D1ES{
        height: 60px;
    }
    
    .about_thsLogo__3dtZ6{
        height: 60px;
        
    }
}
.report_mainContainer__s_3JB{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.report_mainContainerGrey__3Bx1u{
    background-color: #F2F2F2;
    padding-bottom: 40px;
}


.report_mainContainerRecommendItem__BG50-{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
}

.report_mainBody__pL2aT{
     width: 70%;
}

.report_divider__176dQ{
    padding-top: 20px;
    padding-bottom: 20px;
}

.report_reportTitle__32zYO{
    color: #012169;
    font-size: x-large;
    font-weight: bolder;
    margin-top: 40px;
}

.report_title__JrcdT{
    color: #012169;
    font-size: large;
    font-weight: bolder;
    margin-top: 40px; 
}

.report_subTitle__1rJqY{
    color: #012169;
    font-size: large;
    font-weight: bolder;
    margin-top: 10px;
}

.report_subTitle2__2ewr3{
    color: #012169;
    font-size: medium;
    font-weight: bolder;
    margin-top: 10px;
}

.report_noMargin__8mevs{
    margin-top: 0px;
}

.report_body__1jPrc{
    margin-top: 10px;
    font-size: medium;
}

.report_bodyHighlighted__1IeUQ{
    font-size: medium;
    color: #012169;
    font-weight: bold;
}

.report_exampleHeader__IVeur{
    font-style: italic;
}

.report_source__OlTjr{
    font-style: italic;
    font-size: small;
}

.report_recommendContainer__17iIq{
    display: flex;
    flex-direction: row;
}

.report_recommendItem__2ArJ5{
    width: 25%;
    padding: 10px;
}

.report_needHigh__2blZo{
    background-color: #FEEAEB;
}

.report_needHigh__2blZo .report_subTitle2__2ewr3 {
    color: red;
}

.report_needModerate__1FAkm{
    background-color: #FCF1ED;
}

.report_needModerate__1FAkm .report_subTitle2__2ewr3 {
    color: #ED7C31;
}

.report_needLow__2Ss3z{
    background-color: #F9F5EA;
}

.report_needLow__2Ss3z .report_subTitle2__2ewr3 {
    color: #D3A005;
}

.report_needLowest__28nl7{
    background-color: #B3F7AF;
}

.report_needLowest__28nl7 .report_subTitle2__2ewr3 {
    color: green;
}


.faq_mainBody__1HlYZ{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.faq_title__vPKo3{
    color: #012169;
    font-size: larger;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.faq_body__1K7Ig{
    margin-top: 10px;
}

.faq_bodyHighlighted__2XxFi{
    margin-top: 10px;
    font-size: medium;
    color: #012169;
    font-weight: bold;
}
:root {
  --duke-blue:#001A57;
}

body{
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif
}

.mainContainer{
  display: flex;
  flex-direction: column;
}

.map-container {
  height: 600px;
  }

.mainHeader{
  height: 80px;
  width: 100%;
}

.mainBody{
  position: relative;
  height: 100%;
}

.mainFooter{
  width: 100%;
}

.legend {
  position: absolute;
  top: 1em;
  left: 1em;
}

.p-dialog .p-dialog-header {
  background: transparent;
  padding: 0px;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  background: transparent;
}

.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link{
  background: transparent;

}

.btn{
  background-color: #001A57;
  background-color: var(--duke-blue);
  color: white;
  cursor: pointer;
  padding: 5px;
}

.btn:disabled{
  background-color: grey;
  cursor: auto;
}

.mapboxgl-popup-content{
  opacity: 0.85;
}

.mobile-warning{
  font-size: large;
  background-color: lightcoral;
  text-align: center;
  font-weight: bold;
  height: 30px;
}

.mobile-warning-btn{
  float: right;
}

.mainFooterList{
  opacity: 1;
}

.mainFooterList ul li {
  position: relative;
	padding-bottom: 10px;
}

.mainFooterList ul {
  list-style: none;
}

.mainFooterList ul li:before{
    content: "";
    -webkit-mask: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E") no-repeat 50% 50%;
    mask: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #0577b1;
    width: .9375rem;
    height: .9375rem;
    margin-right: .9375rem;
}

.html2canvas-container { width: 2000px !important; height: 2000px !important; }
.mainHeader_mainContainer__1LIV7{
    display: block;
    width: 100%;
    background: var(--duke-blue);
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.mainHeader_header__2-vf_{
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
}

.mainHeader_dukeLogo__2Cicw{
    flex: 1 1;
}

.mainHeader_dukeLogoImage__3kUUS{
    padding: 10px;
}

.mainHeader_navigation__7Oa1X{
    display: flex;
    flex-direction: column;
    padding-top: 5px;
}


.mainHeader_optionsGridContainer__iLCD1 {
    position: relative;
    display: grid;
    grid-template-areas:
      'explore monitor about faq tutorial';      
    width:300px;
    height:100%; 
    background: transparent;
    margin-right: 50px;
    margin-top: 30px;
}

.mainHeader_optionItemExplore__duNDy{
    grid-area: explore;
}

.mainHeader_optionItemMonitor__3dlkv{
    grid-area: monitor;
    
}

.mainHeader_optionItemAbout__1Vxs9{
    grid-area: about;
}

.mainHeader_optionItemFaq__gJrGy{
    grid-area: faq;
}

.mainHeader_optionItemTutorial__2vGku{
    grid-area: tutorial;
    color: white;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
}


.mainHeader_optionItem__3obJo{
    color: white;    
    text-align: center;
    padding-top: 5px;
    cursor: pointer;
    font-size: 1rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
}

.mainHeader_dukeLogo__2Cicw{
    height: 80px;
}

.mainHeader_thsLogo__1Imat{
    height: 80px;
}
.mainFooter_mainContainer__2SbUf{
    display: block;
    background: #012169;

}

.mainFooter_footer__1DmYJ{
    display: flex;
    flex-direction: row;
    max-width: 1220px;
    margin-right: auto;
    margin-left: auto;
}

.mainFooter_footerConnectContainer__2N8l7{
    margin-right: auto;
    margin-left: auto;
    color: white;
    background-color: #00123d;
    border-top-width: 3px;
    border-top-style: solid;
    border-color: #0577b1;
    height: 5.625rem;
}

.mainFooter_footerConnect__Il_83{
    display: flex;
    max-width: 1220px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.mainFooter_footerConnect__Il_83 ul{
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 0.3125rem;
    justify-content: space-between;
    margin-left: 50px;
}

.mainFooter_footerConnectHeading__2MRV0{
    font-weight: 800;
    font-size: 1rem;
}

.mainFooter_footerConnectIcon__1X2Cc{
    height: 35px;
    margin-left: 30px;
}

.mainFooter_footerConnectIcon2__1HCh-{
    height: 27px;
    margin-left: 35px;
}

.mainFooter_acknowledgeContainer__GcPeB{
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


.mainFooter_logoContainer__3qCJY{
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.mainFooter_dukeLogo__3wxXa{
    height: 72px;
}

.mainFooter_thsLogo__2la_N{
    margin-top: 10px;
    height: 80px;
    width: 220px;
}

.mainFooter_ehLogo__3aONq{
    height: 65px;
}

.mainFooter_ehText__36tbX{
    color: white;
    margin-top: 20px;
    width: 280px;
}

.mainFooter_navigation__1mgjp{
    display: flex;
    flex-direction: row;
    flex: 1 1;
    color: white;    
    padding-top: 5px;
    cursor: pointer;
}

.mainFooter_navigationItem__1p4wu{
    padding: 5px;
}

.mainFooter_navigationItemHref__1KCFB{
    color: white;
    text-decoration: none;
}

.mainFooter_divider__kqrhb{
    margin: .9375rem 0 4.6875rem;
    margin-top: 0.9375rem;
    margin-bottom: 4.6875rem;
    margin-left: 2rem;
    border-width: 1px;
    border-color: #00123d;
    border-style: solid;
}

.mainFooter_contact__2lRLl{
    width: 20%;
    margin-left: 2rem;
}

.mainFooter_contact__2lRLl a{
    color: white;
}

.mainFooter_contactTitle__TvIuZ{
    font-weight: 800;
    font-size: 1rem;
}
.login_loginMain__3YJhU{
    background-color: lightgrey;
    height: 100vh;
    position: relative;
}

.login_loginBlock__3jjWJ{
    background-color: var(--duke-blue);
    color: white;
    position: absolute;
    width: 400px;
    min-height: 280px;
    top: calc(50% - 130px);
    left: calc(50% - 200px);
    border: solid;
    border-color: black;
    padding: 30px;
}

.login_label__1s1aU{
    margin-top: 20px;
}

.login_input__2RnMG{
    width: 100%;
    margin-top: 5px;
}

.login_button__29h90{
    margin-top: 10px;
    width: 100%;
    border-radius: 25px;
}

.login_error__3j7Nd{
    color: red;
    margin-top: 10px;
}
