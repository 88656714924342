.mainBody{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.title{
    color: #012169;
    font-size: larger;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.body{
    margin-top: 10px;
}

.bodyHighlighted{
    margin-top: 10px;
    font-size: medium;
    color: #012169;
    font-weight: bold;
}