.dialog{
    width: 550px;
    min-height: 480px;
}

.header{
    color: white;
    background-color: #012169;
    padding: 1rem;
    width: 100%;
    display: inline-flex;
}

.headerGrid{
    display: grid;
    grid-template-areas:
      'title button';  
    columns: auto 50px;
    width: 100%;
}

.headerTitle{
    grid-area:title
}

.headerButton{
    position: relative;
    grid-area: button;
}

.headerCloseButton{
    width: 30px;
    height: 30px;
    color: white;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: box-shadow 0.15s;
    float: right;
    font-size: large;
    cursor: pointer;
}

.headerCloseButtonHover{
    color: black;
}

.title{
    font-weight: 500;
    width: 150px;
    height: 30px;
}

.data{
    padding-left: 3px;
}


.source{
    padding-left: 3px;
    font-size: smaller;
}



.progressSpinner{

    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
}