.mainBody{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.title{
    color: #012169;
    font-size: larger;
    font-weight: bold;
    margin-top: 10px;
}

.body{
    margin-top: 10px;
}

.ehLogo{
    width: 400px;
}


.methodList{
    list-style: none;
}

.logos{
    position: relative;
    display: grid;
    grid-template-areas:
        'Duke Elevance THS';
    width:100%;
    height:100px; 
    margin-top: 50px;
}

.dukeLogo{
    grid-area: Duke;
    height: 100px;
    background-color: var(--duke-blue);
    padding: 10px;
}

.elevanceLogo{
    grid-area: Elevance;
    height: 100px;
}

.thsLogo{
    grid-area: THS;
    height: 100px;
    
}

@media (max-width: 1100px) {
    .dukeLogo{
        height: 80px;
    }

    .elevanceLogo{
        height: 80px;
    }
    
    .thsLogo{
        height: 80px;
        
    }
}

@media (max-width: 900px) {
    .dukeLogo{
        height: 60px;
    }

    .elevanceLogo{
        height: 60px;
    }
    
    .thsLogo{
        height: 60px;
        
    }
}

@media (max-width: 700px) {
    .logos{
        grid-template-areas:
        'Duke'
        'Elevance'
        'THS';
        height: 400px;
    }

    .dukeLogo{
        height: 60px;
    }

    .elevanceLogo{
        height: 60px;
    }
    
    .thsLogo{
        height: 60px;
        
    }
}