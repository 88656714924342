@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700');

:root {
  --duke-blue:#001A57;
}

body{
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif
}

.mainContainer{
  display: flex;
  flex-direction: column;
}

.map-container {
  height: 600px;
  }

.mainHeader{
  height: 80px;
  width: 100%;
}

.mainBody{
  position: relative;
  height: 100%;
}

.mainFooter{
  width: 100%;
}

.legend {
  position: absolute;
  top: 1em;
  left: 1em;
}

.p-dialog .p-dialog-header {
  background: transparent;
  padding: 0px;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  background: transparent;
}

.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link{
  background: transparent;

}

.btn{
  background-color: var(--duke-blue);
  color: white;
  cursor: pointer;
  padding: 5px;
}

.btn:disabled{
  background-color: grey;
  cursor: auto;
}

.mapboxgl-popup-content{
  opacity: 0.85;
}

.mobile-warning{
  font-size: large;
  background-color: lightcoral;
  text-align: center;
  font-weight: bold;
  height: 30px;
}

.mobile-warning-btn{
  float: right;
}

.mainFooterList{
  opacity: 1;
}

.mainFooterList ul li {
  position: relative;
	padding-bottom: 10px;
}

.mainFooterList ul {
  list-style: none;
}

.mainFooterList ul li:before{
    content: "";
    -webkit-mask: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E") no-repeat 50% 50%;
    mask: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #0577b1;
    width: .9375rem;
    height: .9375rem;
    margin-right: .9375rem;
}

.html2canvas-container { width: 2000px !important; height: 2000px !important; }