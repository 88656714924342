.mainContainer{
    display: block;
    background: #012169;

}

.footer{
    display: flex;
    flex-direction: row;
    max-width: 1220px;
    margin-right: auto;
    margin-left: auto;
}

.footerConnectContainer{
    margin-right: auto;
    margin-left: auto;
    color: white;
    background-color: #00123d;
    border-top-width: 3px;
    border-top-style: solid;
    border-color: #0577b1;
    height: 5.625rem;
}

.footerConnect{
    display: flex;
    max-width: 1220px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.footerConnect :global(ul){
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 0.3125rem;
    justify-content: space-between;
    margin-left: 50px;
}

.footerConnectHeading{
    font-weight: 800;
    font-size: 1rem;
}

.footerConnectIcon{
    height: 35px;
    margin-left: 30px;
}

.footerConnectIcon2{
    height: 27px;
    margin-left: 35px;
}

.acknowledgeContainer{
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


.logoContainer{
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.dukeLogo{
    height: 72px;
}

.thsLogo{
    margin-top: 10px;
    height: 80px;
    width: 220px;
}

.ehLogo{
    height: 65px;
}

.ehText{
    color: white;
    margin-top: 20px;
    width: 280px;
}

.navigation{
    display: flex;
    flex-direction: row;
    flex: 1 1 0%;
    color: white;    
    padding-top: 5px;
    cursor: pointer;
}

.navigationItem{
    padding: 5px;
}

.navigationItemHref{
    color: white;
    text-decoration: none;
}

.divider{
    margin: .9375rem 0 4.6875rem;
    margin-top: 0.9375rem;
    margin-bottom: 4.6875rem;
    margin-left: 2rem;
    border-width: 1px;
    border-color: #00123d;
    border-style: solid;
}

.contact{
    width: 20%;
    margin-left: 2rem;
}

.contact :global(a){
    color: white;
}

.contactTitle{
    font-weight: 800;
    font-size: 1rem;
}